import { NavLink, useHistory } from "react-router-dom";
import Logo from '../../assets/logo-a7apps.svg';
import './header.component.scss';

function HeaderComponent() {

  return (
    <>
      <header>
        <div className="container">
          <div className="flex flex-sp flex-cv">
            <NavLink to="/" className='flex flex-cv'>
              <img src={Logo} alt="A7Apps - Soluções WEB" />
              <span>A7Apps</span>
            </NavLink>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderComponent;
