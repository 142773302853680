import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function ListarProjetosService() {
    let url = '/list-projetos';
    return await api.post(url, { email: 'a7apps@' }, config);
}
