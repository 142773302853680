
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import HomePage from './pages/home/home.page';
import LoadingComponent from './components/loading/loading.component';
import SnackComponent from './components/snack/snack.component';

function Routes() {

    return (
        <BrowserRouter>
            <HeaderComponent />
            <Switch>
                <Route exact path="/" component={HomePage} />
                {/* <ProtectedRoute
                    exact={true}
                    path={'/minha-conta'}
                    component={MinhaContaPage}
                /> */}
                <Route component={NotFoundPage} />
            </Switch>
            <SnackComponent />
            <LoadingComponent />
            {/* <CookieConsent
                location="bottom"
                buttonText="Aceitar"
                cookieName="CookieConsent"
                expires={150}
            >
                Este site usa cookies para melhorar a experiência do usuário.
                <NavLink exact={true} to="/politica-de-privacidade" activeClassName="active">
                    <Button>Política de Privacidade</Button>
                </NavLink>
            </CookieConsent> */}
        </BrowserRouter>
    )
}

export default Routes;