import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import { SnackService } from '../../services/snack.service';
import './snack.component.scss';

function SnackComponent() {

  const [openSnack, setIsOpenSnackbar] = React.useState<boolean>(false);
  const [msgSnakbar, setMsgSnakbar] = React.useState<any>('');

  useEffect(() => {
    SnackService.getMSG().subscribe(msg => {
      setMsgSnakbar(msg);
      setIsOpenSnackbar(true);
    });
  }, []);

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpenSnackbar(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={openSnack}
      autoHideDuration={6000}
      onClose={handleCloseSnack}
      message={msgSnakbar}
      className="vermelho"
      action={
        <IconButton onClick={handleCloseSnack}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}

export default SnackComponent;
