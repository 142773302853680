import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import ProjetoModel from '../../models/projeto.model';
import { LoadingService } from '../../services/loading.service';
import { ListarProjetosService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import './home.page.scss';

export default function HomePage() {
  const [listProjetos, setListProjetos] = React.useState<Array<ProjetoModel>>([]);
  const [articleStructuredData, setArticleStructuredData] = React.useState<any>();

  useEffect(() => {
    ListarProjetos();
  }, []);

  useEffect(() => {
    setArticleStructuredData({
      "@context": "https://schema.org",
      "@type": "ItemList",
      "itemListElement": MontaItemListElement()
    })
  }, [listProjetos]);

  const ListarProjetos = () => {
    LoadingService.sendLoadingAtivo(true);
    ListarProjetosService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListProjetos(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar projetos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const MontaItemListElement = () => {
    let arrItemList: any = [];
    listProjetos.map((item: ProjetoModel, index: number) => {
      arrItemList.push({
        "@type": "ItemList",
        "position": index + 1,
        "item": {
          "@type": "ListItem",
          "url": item.url,
          "name": item.titulo,
          "image": item.thumb,
          "provider": {
            "@type": "Organization",
            "name": "A7Apps - Soluções WEB",
            "sameAs": "https://a7apps.com",
          },
        }
      });
    });

    return arrItemList;
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <section className="home">
        <div className="container">
          <div className="projetos flex flex-ch flex-wr">
            {
              listProjetos ? listProjetos.map((item, indexProjeto) => (
                <div className='card-projeto flex flex-wr' key={indexProjeto}>
                  <div className="esq">
                    <h2>{item.titulo}</h2>
                    <h3>{item.descricao}</h3>
                    <Button className='bt' href={item.url} target="_blank">Abrir projeto</Button>
                  </div>
                  <div className="dir flex flex-ch flex-cv">
                    <img src={item.thumb} alt={item.titulo} />
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
        </div>
      </section>
    </>
  );
}